import React, { useState, useRef } from 'react';
import axios from 'axios';



function UploadForm( { triggerUpdate } ) {


  const fileInputRef = useRef(null);

  const [file, setFile] = useState(null);
  const [artist, setArtist] = useState('');
  const [title, setTitle] = useState('');
  const [creator, setCreator] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  const resetFormFields = () => {
    setFile(null);
    setArtist('');
    setTitle('');
    setCreator('');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();


    const formData = new FormData();
    formData.append('file', file);
    formData.append('artist', artist);
    formData.append('title', title);
    formData.append('creator', creator);

    try {
      const response = await axios.post('https://deepwave-api-faac972890c6.herokuapp.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(percentCompleted);
        }
      });

      if (response.status === 200) {
        setUploadStatus('File uploaded successfully');
        triggerUpdate();
        setUploadProgress(0);
        resetFormFields();
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading file', error);
      setUploadStatus('Error uploading file');
    }


    if (!file || !artist || !title ) {
      alert('Please fill in all fields and choose a file to upload.');
      return;
    }
  };


  
  return (
    <div id="uploadForm">
      <form onSubmit={handleFormSubmit}>
        <div>
          <input class="upload" type="file" accept=".mp3" id="fileInput" ref={fileInputRef}  onChange=
          
          {handleFileChange} />
<label class="upload-label" for="fileInput">Choose File</label>

        </div>
        <div>
          <label>Artist</label>
          <input className="uploadField" type="text" value={artist} onChange={(e) => setArtist(e.target.value)} />
        </div>
        <div>
          <label>Title</label>
          <input className="uploadField" type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div>
          <button type="submit">Upload File</button>
        </div>
      </form>
      <p>{uploadStatus}</p>
      <div className="custom-progress-bar-container">
        <div
          className="custom-progress-bar"
          style={{ width: `${uploadProgress}%` }}
        />
      </div>
      <div className="description">
        <p>Deepwave.live is a CRUD (Create, Read, Update & Delete) application which utilizes PostgreSQL, Express, Node & React.<br></br><br></br>The Node API and SQL database are both served from Heroku, while AWS S3 is used for file storage.</p>
      </div>
    </div>
  );
}

export default UploadForm;
