import React, { useState } from 'react';
import axios from 'axios';
import Waveform from './Waveform';

function AudioList({ audioPayLoad, triggerUpdate }) {

  const [updateFormData, setUpdateFormData] = useState({
    audioId: null,
    artist: '',
    title: '',
  });


  const handleDeleteAudio = (audioId) => {
    axios
      .delete(`https://deepwave-api-faac972890c6.herokuapp.com/delete-audio/${audioId}`)
      .then(() => {
        // After successful deletion, refresh the audio list
        triggerUpdate();
      })
      .catch((error) => {
        console.error('Error deleting audio:', error);
      });
  };

  const handleUpdateClick = (audioId, artist, title) => {
    // Set the update form data with the audio information
    setUpdateFormData({
      audioId,
      artist,
      title,
    });
  };

  const handleUpdateSubmit = () => {
    const { audioId, artist, title } = updateFormData;

    axios
      .put(`https://deepwave-api-faac972890c6.herokuapp.com/update-audio/${audioId}`, {
        artist,
        title,
      })
      .then(() => {
        // After successful update, clear the update form and refresh the audio list
        setUpdateFormData({
          audioId: null,
          artist: '',
          title: '',
        });
        triggerUpdate();
      })
      .catch((error) => {
        console.error('Error updating audio:', error);
      });
  };

  return (
    <div id="audioList">
      <ul>
        {audioPayLoad.map((audio) => (
          <li key={audio.id}>
            {/* Display relevant audio information */}
            <div className='audioInfo'>
              <div><h3>{audio.title}</h3></div>
              <div><h4>{audio.artist}</h4></div>
            </div>

            <Waveform source={audio.src} />

            {updateFormData.audioId === audio.id ? (
              <div>
                {/* Display update form */}
                <input
                  className='update'
                  type="text"
                  placeholder="New Artist"
                  value={updateFormData.artist}
                  onChange={(e) =>
                    setUpdateFormData({
                      ...updateFormData,
                      artist: e.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  className='update'
                  placeholder="New Title"
                  value={updateFormData.title}
                  onChange={(e) =>
                    setUpdateFormData({
                      ...updateFormData,
                      title: e.target.value,
                    })
                  }
                />
                <button onClick={handleUpdateSubmit}>Save</button>
              </div>
            ) : (
              <button onClick={() => handleUpdateClick(audio.id, audio.artist, audio.title)}>
                Update
              </button>
            )}

            <button onClick={() => handleDeleteAudio(audio.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AudioList;
