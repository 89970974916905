import React, { useState } from 'react';
import PropTypes from 'prop-types';

async function loginUser(credentials) {
  const response = await fetch('https://deepwave-api-faac972890c6.herokuapp.com/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  });

  if (!response.ok) {
    // Handle HTTP error status codes (e.g., 401 for invalid credentials)
    throw new Error('Error in API request');
  }

  const data = await response.json();
  return data;
}


export default function Login({ setToken }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    
    try {
      const token = await loginUser({
        username,
        password
      });
      setToken(token);
    } catch (error) {
      setError('Wrong credentials, please try again.');
      console.error('Login Error:', error);
      console.log('Error State:', error);
    }
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
