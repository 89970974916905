import './App.css';
import React, { useState, useEffect} from 'react';
import Login from './components/Login/Login';
import AudioList from './components/Global/AudioList';
import Header from './components/Global/Header'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import useToken from './useToken';
import  UploadForm from './components/Global/UploadForm';
import axios from 'axios';

function App() {
  //login
  const { token, setToken } = useToken();

  //set up vars for audio payload
  const [audioData, setAudioData] = useState([]);

  // Fetch audio data when the component mounts
  useEffect(() => {
    fetchAudioData();
    console.log('fetchAudioData triggered');
  }, []);

  const fetchAudioData = () => {
    axios
      .get('https://deepwave-api-faac972890c6.herokuapp.com/all-audio')
      .then((response) => {
        // Sort the audio data by date_created in descending order (most recent first)
        const sortedAudioData = response.data.sort((a, b) =>
          new Date(b.date_created) - new Date(a.date_created)
        );
        setAudioData(sortedAudioData);
      })
      .catch((error) => {
        console.error('Error fetching audio data:', error);
      });
  };


  if(!token) {
    return (
      <> <Header />
      <Login setToken={setToken} /></>
)
  }


  return (


    <div className="wrapper">
    <Header></Header>
      <BrowserRouter>
        <Switch>
          <Route path="/">
            <UploadForm triggerUpdate={fetchAudioData}/>
            <AudioList audioPayLoad={audioData} triggerUpdate={fetchAudioData}/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
